import {
  delay,
  take,
  call,
  fork,
  select,
  all,
  put,
  takeLatest,
  takeEvery,
  cancel,
  race
} from "redux-saga/effects";
import { Types, setPhoneRecordingStatus } from "actions/phone";
import { formatDate } from "utils/dates";
import { message, errorMessage } from "actions/message";
import { listenCallEvents } from "./event-handler";
import { selectors } from "reducers";
import * as api from "utils/api";
import { setDialerCallPromptStatus } from "actions/auto-dialer";
/* eslint-disable no-console */

function* watchHangup(_, device) {
  try {
    while (true) {
      yield take(Types.HANGUP);
      device.disconnectAll();
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchMute(twilioCall) {
  while (true) {
    yield take(Types.TOGGLE_MUTE);
    const isMuted = twilioCall.isMuted();
    twilioCall.mute(!isMuted);
  }
}

function* watchAccept(twilioCall) {
  while (true) {
    yield take(Types.ACCEPT_INCOMING);
    twilioCall.accept();
  }
}

function* watchReject(twilioCall) {
  while (true) {
    yield take(Types.REJECT_INCOMING);
    twilioCall.reject();
  }
}

function* watchPauseRecording() {
  yield takeLatest(
    Types.PAUSE_PHONE_RECORDING,
    updatePhoneRecordingStatus,
    true
  );
}

function* watchResumeRecording() {
  yield takeLatest(
    Types.RESUME_PHONE_RECORDING,
    updatePhoneRecordingStatus,
    false
  );
}

function* updatePhoneRecordingStatus(pause) {
  try {
    const callDirection = yield select(state =>
      selectors.getCallDirection(state)
    );
    const { status } = yield call(
      api.post,
      `twilio/new/general/recording/${callDirection}/${pause}`
    );
    yield put(setPhoneRecordingStatus(status));
  } catch (e) {
    console.error(e);
  }
}

export function* watchCallActions(twilioCall, device) {
  yield all([
    call(watchHangup, twilioCall, device),
    call(watchMute, twilioCall),
    call(watchAccept, twilioCall),
    call(watchReject, twilioCall),
    call(watchPauseRecording, twilioCall),
    call(watchResumeRecording, twilioCall),
    call(watchAutomatedVoicemailRequest, twilioCall),
    call(watchDigitInputTask, twilioCall)
  ]);
}

function* watchAutomatedVoicemailRequest(twilioCall) {
  while (true) {
    try {
      const {
        payload: { patientGuid }
      } = yield take(Types.PLAY_AUTOMATED_VOICEMAIL);
      const dialedNumber = twilioCall?.customParameters?.get("To");
      const {
        message: status,
        outreachDate,
        outreachType
      } = yield call(
        api.post,
        `twilio/new/transfer/RedirectCallIntoVoicemailSay/${dialedNumber}/${patientGuid}`
      );
      yield put(
        message(
          `${status}, changed outreach to ${outreachType}  ${formatDate(
            outreachDate,
            "L"
          )}`
        )
      );
      yield put({ type: Types.PLAY_AUTOMATED_VOICEMAIL_SUCCESS });
      yield put({ type: Types.HANGUP });
    } catch (error) {
      yield put(
        errorMessage(
          `An error occurred while trying to leave an automated voicemail. ${error}`
        )
      );
    }
  }
}

export function* handleCallConnection(device, action) {
  try {
    const { twilioCall } = action.payload;
    const watchCallActionsTask = yield fork(
      watchCallActions,
      twilioCall,
      device
    );
    const callTask = yield fork(listenCallEvents, twilioCall, device);
    const durationTask = yield fork(updateCallDuration);

    //Ask for call disposition here
    const { leftMessage } = yield race({
      leftMessage: take(Types.PLAY_AUTOMATED_VOICEMAIL_SUCCESS),
      ended: take(Types.TWILIO_EVENT_DISCONNECT)
    });
    //If we don't leave a message, ask for disposition
    //If we do, skip disposition
    var orderPlaced = yield select(state =>
      selectors.getAutodialerPlacedOrder(state)
    );
    if (!leftMessage && !orderPlaced)
      yield put(setDialerCallPromptStatus(true));
    else yield take(Types.TWILIO_EVENT_DISCONNECT);
    yield cancel([
      // connectionHandlerTask,
      callTask,
      durationTask,
      watchCallActionsTask
    ]);
  } catch (e) {
    console.error(e);
  }
}

function* watchDigitInputTask(twilioCall) {
  yield takeEvery(Types.SEND_DIGITS, function ({ payload: { digits } }) {
    if (/^([0-9]|\*|#)+$/.test(digits)) {
      twilioCall.sendDigits(digits);
    }
  });
}

function* updateCallDuration() {
  let seconds = 0;
  while (true) {
    yield put({
      type: Types.CALL_DURATION,
      payload: { seconds }
    });
    ++seconds;
    yield delay(1000);
  }
}

export default function* watchConnection(device) {
  yield takeEvery(Types.TWILIO_EVENT_CONNECT, handleCallConnection, device);
}
