import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { getPDFTemplate } from "components/dd_newModules/Redux/Actions/pdftemplate";
import { FaFileExcelO } from "react-icons/lib/fa";
import { useFetch } from "hooks";
import { path } from "ramda";
import { post } from "utils/api";
import { getApiErrorMessage } from "utils/misc";
import { Spinner } from "components/ui";
import { errorMessage } from "actions/message";
import { browserHistory } from "browser-history";
import { setPatientBatchIds } from "actions/batch";
import { createTab } from "actions/system-tray";

const formatDate = date => (date ? moment(date).format("MM/DD/YYYY") : null);

const AssignedPatients = ({ PDFTemplates, selectedDocumentId }) => {
  const filtered = PDFTemplates.filter(pdf => pdf.id == selectedDocumentId);
  const selected = filtered[0];
  const dispatch = useDispatch();

  const { fetchData, isFetching } = useFetch({
    apiFn: () =>
      post(
        `dme_portal/signature_documents/${selectedDocumentId}/export_patients`
      ),
    defaultValue: null,
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(
        errorMessage(
          `Error when trying to export patients. ${getApiErrorMessage(error)}`
        )
      );
    }
  });

  async function exportPatients() {
    const exportSuccessful = await fetchData();
    if (exportSuccessful) {
      browserHistory.push("/exports");
    }
  }

  return (
    <div className="special-fields" style={{ height: "100%" }}>
      <header className="page-header">
        <div className="page-header-title">
          <h1>
            <span className="page-title">
              {`Patients Assigned to ${selected.document_title} document`}
              {!isFetching && (
                <FaFileExcelO
                  size={20}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    color: "#4281a6"
                  }}
                  onClick={exportPatients}
                />
              )}
              {isFetching && <Spinner />}
            </span>
          </h1>
        </div>
      </header>

      <div style={{ overflowX: "scroll", height: "93%" }}>
        <div
          style={{
            display: "grid",
            gridGap: 10,
            alignItems: "left",
            height: "fit-content"
          }}
        >
          {selected && (
            <table className="large-12 ">
              <thead>
                <tr>
                  <th>Document</th>
                  <th>Patient</th>
                  <th>Account Number</th>
                  <th>Company</th>
                  <th>Status</th>
                  <th>Sent By</th>
                  <th>Date Sent</th>
                </tr>
              </thead>
              <tbody>
                {selected?.patients?.map(p => {
                  return (
                    <tr key={p.id}>
                      <td>{selected.document_title}</td>
                      <td>
                        <span
                          onClick={() => {
                            dispatch(setPatientBatchIds([p.id], [p.name]));
                            dispatch(
                              createTab({
                                type: "patients",
                                key: "patients",
                                label: `Patients (1)`,
                                meta: { ids: [p.id] }
                              })
                            );
                          }}
                          style={{
                            color: "#4281A6",
                            lineHeight: "inherit",
                            textDecoration: "none",
                            cursor: "pointer"
                          }}
                        >
                          {p.name}
                        </span>
                      </td>
                      <td>{p.account_number}</td>
                      <td>{p.company}</td>
                      <td>{p.status == 0 ? "not signed" : "signed"}</td>
                      <td>{p.sent_by}</td>
                      <td>{formatDate(selected?.date_of_delivery)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    PDFTemplates: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate
  }),
  {
    getPDFTemplate
  }
)(AssignedPatients);

AssignedPatients.propTypes = {
  PDFTemplates: PropTypes.array,
  selectedDocumentId: PropTypes.string
};
